<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 8 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 4 }"
            :span="4"
          >
            <a-form-item
              label="年份"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="年份"
                v-model="queryParams.year"
                style="width: 100%"
              >
                <a-select-option
                  v-for="t in skillYears"
                  :key="t"
                  :value="t"
                  >{{ t }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="operator">
      
    </div>
    <div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 900 }"
      >
      </a-table>
    </div>
  </a-card>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "skillUser",
  components: { },
  data() {
    return {
      skillYears: [],
      dataSource: [],
      pagination: {
        pageSizeOptions: ["1", "10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        total: 0,
        current: 1,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
        onChange: (current, pageSize) => {
          this.pagination.current = current;
          this.pagination.pageSize = pageSize;
        },
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
        },
      },
      queryParams: {
        year: undefined,
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      userType: (state) => state.dict.dicts.t_user_user_type,
    }),
    columns() {
      return [
        {
          title: "姓名",
          dataIndex: "username",
          width: 100,
        },
        {
          title: "员工编号",
          dataIndex: "empno",
          width: 100,
        },
        {
          title: "部门",
          dataIndex: "deptName",
          width: 200,
        },
        {
          title: "职位",
          dataIndex: "deptPositionName",
          width: 200,
        },
        {
          title: "员工类别",
          width: 100,
          dataIndex: "userType",
          customRender: (text, row, index) => {
            for (let _index in this.userType) {
              let key = this.userType[_index].keyy;
              if (text === key) {
                let val = this.userType[_index].valuee;
                let color = this.userType[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return text;
          },
        },
        {
          title: "申请时间",
          width: 200,
          dataIndex: "createTime",
        },
        {
          title: "申请年份",
          width: 100,
          dataIndex: "year",
        },
      ];
    },
  },
  mounted() {
    this.$get("user-skill/year").then((r) => {
      this.skillYears = r.data.data;
      this.fetch();
    });
  },
  methods: {
    reset() {
      // 重置查询参数
      this.queryParams = { trainingMethod: 1 };
      this.pagination.current = 1;
      this.fetch();
    },
    handleTableChange(pagination, filters, sorter) {
      this.fetch();
    },
    fetch() {
      this.loading = true;
      this.queryParams.pageSize = this.pagination.pageSize;
      this.queryParams.pageNum = this.pagination.current;

      this.$get("user-skill", {
        ...this.queryParams,
      }).then((r) => {
        let data = r.data
        const pagination = { ...this.pagination }
        pagination.total = data.total
        this.loading = false
        this.dataSource = data.rows
        this.pagination = pagination
      });
    },
    search() {
      this.fetch();
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../utils/Common.less";
</style>